import axios from 'axios'
import store from '@/store'
// import notification from 'ant-design-vue/es/notification'
import { VueAxios } from './axios'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { Toast } from 'mint-ui' // 引入提示弹窗组件
import { getCookie } from '@/utils/domUtil'

// 创建 axios 实例
const service = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL, // api base_url
  timeout: 30000 // 请求超时时间
})

const err = (error) => {
  if (error.response) {
    const data = error.response.data
    const token = getCookie(ACCESS_TOKEN)
    if (error.response.status === 403) {
      //  适用移动端的提示
      const instance = Toast({
        message: '稍后重试',
        position: 'middle',
        duration: 3000
      })
      setTimeout(() => {
        instance.close()
      }, 1500)
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      console.log('Unauthorized')
      // 会话过期和账号密码错误都是 401状态码，用msg内容区分，如果是会话过期不提示跳到登录页
      if (data.respMsg != null && !data.respMsg.startsWith('Invalid access token')) {
        Toast({
          message: data.respMsg,
          position: 'middle',
          duration: 2000
        })
      }
      if (token) {
        store.dispatch('Logout').then(() => {
          window.location.reload()
        })
      }
    }
    if (error.response.status === 400) {
      const instance = Toast({
        message: data.respMsg,
        position: 'middle',
        duration: 3000
      })
      setTimeout(() => {
        instance.close()
      }, 1500)
    }
    if (error.response.status === 500) {
      const instance = Toast({
        message: data.respMsg || '出现点小问题',
        position: 'middle',
        duration: 3000
      })
      setTimeout(() => {
        instance.close()
      }, 1500)
    }
  }
  return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use((config) => {
  const token = getCookie(ACCESS_TOKEN)
  if (token) {
    config.headers['X-Access-Token'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
  }
  return config
}, err)

// response interceptor
service.interceptors.response.use((response) => {
  return response.data
}, err)

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, service)
  }
}

export { installer as VueAxios, service as axios }
