/**
 * 项目默认配置项
 * primaryColor - 默认主题色, 如果修改颜色不生效，请清理 localStorage
 * navTheme - sidebar theme ['dark', 'light'] 两种主题
 * colorWeak - 色盲模式
 * layout - 整体布局方式 ['sidemenu', 'topmenu'] 两种布局
 * fixedHeader - 固定 Header : boolean
 * fixSiderbar - 固定左侧菜单栏 ： boolean
 * autoHideHeader - 向下滚动时，隐藏 Header : boolean
 * contentWidth - 内容区布局： 流式 |  固定
 *
 * storageOptions: {} - Vue-ls 插件配置项 (localStorage/sessionStorage)
 *
 */

export default {
  primaryColor: '#52C41A', // primary color of ant design
  navTheme: 'dark', // theme for nav menu
  layout: 'sidemenu', // nav menu position: sidemenu or topmenu
  contentWidth: 'Fixed', // layout of content: Fluid or Fixed, only works when layout is topmenu
  fixedHeader: false, // sticky header
  fixSiderbar: false, // sticky siderbar
  autoHideHeader: false, //  auto hide header
  colorWeak: false,
  multiTab: false,
  production: process.env.NODE_ENV === 'production' && process.env.VUE_APP_PREVIEW !== 'true',
  // vue-ls options
  storageOptions: {
    namespace: 'pro__', // key prefix
    name: 'ls', // name variable Vue.[ls] or this.[$ls],
    storage: 'local' // storage name session, local, memory
  },

  // 需求状态(包含描述及颜色)
  statusEnum: {
    '1': {
      title: '待审核',
      color: '#F94C17',
      bgColor: 'rgba(249, 76, 23, .1)'
    },
    '2': {
      title: '发布中',
      color: '#1CCE71',
      bgColor: '#E8FAF1'
    },
    '3': {
      title: '洽谈中',
      color: '#178CF9',
      bgColor: '#E7F3FE'
    },
    '4': {
      title: '已结束',
      color: '#999999'
    },
    '5': {
      title: '已过期',
      color: '#999999'
    }
  },
  // 图片类型列表
  imgTypeList: ['jpg', 'jpeg', 'png', 'gif', 'webp']
}
