import Vue from 'vue'
import VueStorage from 'vue-ls'
import config from '@/config/defaultSettings'

// ext library
import PermissionHelper from '@/utils/helper/permission'
import './directives/action'
// TODO 改成按需加载 使用那些导入那些
import MintUI from 'mint-ui'
import 'mint-ui/lib/style.css'

Vue.use(VueStorage, config.storageOptions)
Vue.use(PermissionHelper)
// 注入mui模块
Vue.use(MintUI)
