// with polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/'
// import echarts from 'echarts'
import { VueAxios } from './utils/request'
import { InfiniteScroll } from 'mint-ui'
// import VueScrollTo from 'vue-scrollto'
import { TimeSelect, Select, Option, Radio, RadioGroup } from 'element-ui'
import 'element-ui/lib/theme-default/index.css'

// mock
// WARNING: `mockjs` NOT SUPPORT `IE` PLEASE DO NOT USE IN `production` ENV.
// import './mock'

import bootstrap from './core/bootstrap'
import './core/lazy_use'
import './permission' // permission control
import './utils/filter' // global filter
import './components/global.less'

Vue.config.productionTip = false
// Vue.prototype.$echarts = echarts

// mount axios Vue.$http and this.$http
Vue.use(TimeSelect)
Vue.use(Select)
Vue.use(Option)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(VueAxios)

// 引入mint-ui所需控件
Vue.use(InfiniteScroll)
// Vue.use(VueScrollTo)

new Vue({
  router,
  store,
  created: bootstrap,
  render: (h) => h(App)
}).$mount('#app')
