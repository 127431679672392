<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { AppDeviceEnquire } from '@/utils/mixin'

export default {
  mixins: [AppDeviceEnquire],
  data() {
    return {
      locale: {}
    }
  },
  mounted() {}
}
</script>
<style lang="less">
#app {
  min-height: 100%;
}

/*根元素大小使用vw单位*/
html {
  // font-size: ($vm_fontsize / ($vm_design / 2)) * 100vw;
  // 同时，通过Media Queries 限制根元素最大最小值
  @media screen and (max-width: 320px) {
    font-size: 64px;
  }
  @media screen and (min-width: 2732px) {
    font-size: 564px;
  }
}

/*解决浏览器报错:
Unable to preventDefault inside passive event listener due to target being treated as passive
*/
* {
  touch-action: pan-y;
}

/*body也增加最大最小宽度限制，避免默认100%宽度的block元素跟随 body而过大过小*/
body {
  max-width: 1024px;
  min-width: 320px;
  margin: 0;
  padding: 0;
}

div,
p,
section,
article,
h1,
h2,
h3,
h4,
h5,
h6,
table,
tr,
ul,
li {
  /*解决pandding增大盒子宽度的问题*/
  -moz-box-sizing: border-box; /*Firefox3.5+*/
  -webkit-box-sizing: border-box; /*Safari3.2+*/
  -o-box-sizing: border-box; /*Opera9.6*/
  -ms-box-sizing: border-box; /*IE8*/
  box-sizing: border-box; /*W3C标准(IE9+，Safari5.1+,Chrome10.0+,Opera10.6+都符合box-sizing的w3c标准语法)*/
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  margin: 0;
  padding: 0;
}

#app {
  font-size: 16px;
  font-family: SourceHanSansSC-regular, Arial;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

a {
  text-decoration: none;
}

a:-webkit-any-link {
  color: #333;
  cursor: pointer;
}

ul:after {
  /*伪元素是行内元素 正常浏览器清除浮动方法*/
  content: '';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

ul {
  *zoom: 1; /*ie6清除浮动的方式 *号只有IE6-IE7执行，其他浏览器不执行*/
  list-style: none;
}

input {
  /* 移除手机边框 */
  outline: none;
}

textarea {
  resize: none;
  outline: none;
}

/* 设置input输入框placeholder字体颜色，并兼容所有主流浏览器 */
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #c8c8cf;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #c8c8cf;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #c8c8cf;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c8c8cf;
}

// 搜素
//.mint-searchbar{
//  background-color:#fff!important;
//}
//.mint-searchbar{
//  margin-left:20px;
//  padding:8px 0!important;
//}
//.mint-search{
//  width: 164px;
//}
//.mint-searchbar-inner{
//  background-color:#F3F3F3!important;
//  border-radius: 20px!important;
//  .mintui-search{
//    font-size:16px!important;
//    padding-left:10px;
//    vertical-align: middle;
//  }
//   .mint-searchbar-core{
//      background-color:#F3F3F3!important;
//      font-size:15px;
//      vertical-align: middle;
//      padding-left:5px;
//   }
//}
.mint-searchbar-cancel {
  line-height: 10px;
  font-size: 10px;
  margin-left: 4px;
}

.vistor {
  background: #f5f5f5 !important;
  height: 100vh;
}

// .flo{
//    overflow: hidden;
//   white-space: nowrap;
//   text-overflow: ellipsis;
//   word-break: break-all;
// }
.menu .mint-navbar .mint-tab-item {
  color: #333;
  font-size: 15px;
  font-weight: bold;

  .mint-tab-item-label {
    font-size: 15px;
  }
}

.menu .mint-navbar .mint-tab-item.is-selected {
  border-bottom: none !important;
  color: #178cf9 !important;
}

.mint-indicator {
  font-size: 12px;
}

.edit {
  margin-bottom: 30px;
  width: 100%;

  img {
    width: 100%;
  }
}

.vistorTime .picker-slot-center:nth-child(3) {
  display: none;
}

.vistor .mint-search-list {
  position: relative;
}

.mint-searchbar-inner .mintui-search {
  margin-top: 4px;
}

.mint-searchbar-inner .mint-searchbar-core {
  height: 23px;
  font-size: 12px;
}

.pay .mint-cell-wrapper {
  background-image: none;
}

.displaynone input {
  display: none;
}

.displaynone .mint-field-other {
  left: -17%;
}

.displaynone .mint-cell {
  min-height: 33px;
  font-size: 12px;
}

.displaynone .mint-cell-title {
  width: 120px !important;
}

.el-date-editor.el-input {
  width: 85px !important;
}

.el-input__inner {
  font-size: 15px !important;
  height: 32px !important;
}

.loading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.nodata {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #666666;
}
</style>
