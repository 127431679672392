import { axios } from '@/utils/request'
// import qs from 'qs'

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 *
 * @param parameter
 * @returns {*}
 */

// 企业经营类型
export function businessType(params) {
  return axios({
    url: '/api-user/dict/company-business-type',
    method: 'get',
    params
  })
}

// 收藏
export function actionsSwitch(parameter) {
  return axios({
    url: '/business/tbSysFavorite/actions/switch',
    method: 'post',
    data: parameter
  })
}

export function upload(parameter) {
  return axios({
    url: '/api-file/upload/public',
    method: 'post',
    data: parameter,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function uploadFile(parameter) {
  return axios({
    url: '/api-file/public/upload/public',
    method: 'post',
    data: parameter
  })
}

// export function uploadBase64(parameter) {
//   return axios({
//     url: '/api-file/public/upload/base64-v2',
//     method: 'post',
//     data: qs.stringify(parameter)
//   })
// }

// 企业类型
export function companyType(parameter) {
  return axios({
    url: '/api-user/dict/company-type',
    method: 'get',
    params: parameter
  })
}

// 行业分类
export function industry(parameter) {
  return axios({
    url: '/api-user/users-anon/industry',
    method: 'get',
    params: parameter
  })
}

// 省市区
export function area(parameter) {
  return axios({
    url: '/api-user/users-anon/area',
    method: 'get',
    params: parameter
  })
}

// 聚合搜索
export function publicSearch(parameter) {
  return axios({
    url: 'jeecg-boot/public/search/home',
    method: 'get',
    params: parameter
  })
}

// 资讯搜索
export function newsSearch(parameter) {
  return axios({
    url: 'jeecg-home/home/tbHomeNews/public/findArticleByKeyword',
    method: 'get',
    params: parameter
  })
}

// 创建或取消收藏
export function switchFavorite(data) {
  return axios({
    url: '/business/tbSysFavorite/actions/switch',
    method: 'post',
    data
  })
}

// 聚合搜索
export function HomeSearch(parameter) {
  return axios({
    url: 'jeecg-boot/public/search/home',
    method: 'get',
    params: parameter
  })
}

// 需求大厅
export function projectSearch(parameter) {
  return axios({
    url: '/org/tbProject/public/projects',
    method: 'get',
    params: parameter
  })
}

// 查询模板详情
export function template(id) {
  return axios({
    url: '/news/tbOpTemplate/public/template/' + id,
    method: 'get'
  })
}

// 背景图列表
export function banner() {
  return axios({
    url: '/news/tbOpBanner/public/list',
    method: 'get'
  })
}

// 获取全部字典数据
export function queryAllDictItems(params) {
  return axios({
    url: '/sys/dict/queryAllDictItems',
    method: 'get',
    params
  })
}

// 活动列表
export function tbActivityList(parameter) {
  return axios({
    url: '/org/tbActivity/public/list',
    method: 'get',
    params: parameter
  })
}
