// eslint-disable-next-line
import { BlankLayout } from '@/layouts'
// import store from '../store'
export const asyncRouterMap = []

/**
 * 基础路由
 * 需求登录的页面 统一以 /admin开头
 * @type { *[] }
 */
export const constantRouterMap = [
  // 注册页面
  {
    path: '/',
    name: 'index',
    component: BlankLayout,
    redirect: 'login',
    children: [
      {
        path: 'bind-login',
        name: 'bind-login',
        meta: { title: '登录', keepAlive: false },
        component: () => import('@/views/login/WechatBind')
      },
      {
        path: 'login',
        name: 'login',
        meta: { title: '登录', keepAlive: false },
        component: () => import('@/views/login/Login')
      },
      {
        path: 'register',
        name: 'register',
        meta: { title: '注册', keepAlive: false },
        component: () => import('@/views/login/Register')
      },
      {
        path: 'rest-password',
        name: 'rest-password',
        meta: { title: '找回密码', keepAlive: false },
        component: () => import('@/views/login/ResetPassword')
      },
      {
        path: 'news-detail',
        name: 'news-detail',
        meta: { title: '公告详情', keepAlive: false },
        component: () => import('@/views/news/NewsDetail')
      },
      // 资讯搜索
      {
        path: 'Search',
        name: 'search',
        meta: { title: '搜索', keepAlive: false },
        component: () => import('@/views/Search')
      },
      {
        path: '/job-test',
        name: 'job-test',
        component: () => import('@/views/me/JobTest')
      },
      {
        path: '/my-qr-code',
        name: 'my-qr-code',
        component: () => import('@/views/me/Myqrcode')
      },
      // 设置
      {
        path: '/settings/home',
        name: 'settings-home',
        meta: { title: '设置', keepAlive: false },
        component: () => import('@/views/me/settings/Home')
      },
      {
        path: '/settings/restpwdsms',
        name: 'settings-restpwdsms',
        meta: { title: '修改密码', keepAlive: false },
        component: () => import('@/views/me/settings/RestPwdSms')
      },
      {
        path: '/template',
        name: 'template',
        meta: { title: '详情页', keepAlive: false },
        component: () => import('@/views/me/Template')
      },
      {
        path: '/register-apply',
        name: 'register-apply',
        meta: { title: '入住申请流程', keepAlive: false },
        component: () => import('@/views/home/RegisterApply')
      },
      // 园区
      {
        path: '/introduction',
        name: 'introduction',
        meta: { title: '园区简介', keepAlive: false },
        component: () => import('@/views/park/Introduction')
      },
      {
        path: '/dynamic/list',
        name: 'dynamic',
        meta: { title: '园区动态', keepAlive: false },
        component: () => import('@/views/park/dynamic/List')
      },
      {
        path: '/dynamic/Detail/:id',
        name: 'dynamicDetail',
        meta: { title: '园区动态', keepAlive: false },
        component: () => import('@/views/park/dynamic/Detail')
      },
      {
        path: '/activity/list',
        name: 'activity',
        meta: { title: '园区活动', keepAlive: false },
        component: () => import('@/views/park/activity/List')
      },
      {
        path: '/activity/detail/:id',
        name: 'activityDetail',
        meta: { title: '园区活动', keepAlive: false },
        component: () => import('@/views/park/activity/Detail')
      },
      {
        path: '/checkinGuide',
        name: 'checkinGuide',
        meta: { title: '入驻指南', keepAlive: false },
        component: () => import('@/views/park/CheckinGuide')
      },
      {
        path: '/contactUs',
        name: 'contactUs',
        meta: { title: '联系我们', keepAlive: false },
        component: () => import('@/views/park/ContactUs')
      },
      // 物业
      {
        path: '/property',
        name: 'property',
        meta: { title: '物业服务', keepAlive: false },
        component: () => import('@/views/property/Home')
      },
      {
        path: '/property/repair/list',
        name: 'needRepairList',
        meta: { title: '报修服务列表', keepAlive: false },
        component: () => import('@/views/property/repair/List')
      },
      {
        path: '/property/repair/start',
        name: 'needRepairStart',
        meta: { title: '确认完成', keepAlive: false },
        component: () => import('@/views/property/repair/Start')
      },
      {
        path: '/property/repair/detail',
        name: 'needRepairDetail',
        meta: { title: '查看报修详情', keepAlive: false },
        component: () => import('@/views/property/repair/Detail')
      },
      {
        path: '/property/repair/add',
        name: 'needRepairAdd',
        meta: { title: '新增报修', keepAlive: false },
        component: () => import('@/views/property/repair/Add')
      },
      {
        path: '/property/cost/property',
        name: 'propertyFee',
        meta: { title: '物业费', keepAlive: false },
        component: () => import('@/views/property/cost/Property')
      },
      {
        path: '/property/cost/utilityBills',
        name: 'utilityBills',
        meta: { title: '费用查看', keepAlive: false },
        component: () => import('@/views/property/cost/List')
      }
    ]
  }
]
