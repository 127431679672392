const getters = {
  nav: state => state.app.nav,
  device: state => state.app.device,
  theme: state => state.app.theme,
  color: state => state.app.color,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  username: state => state.user.username,
  welcome: state => state.user.welcome,
  roles: state => state.user.roles,
  perms: state => state.user.permissions,
  userInfo: state => state.user.info,
  addRouters: state => state.permission.addRouters,
  multiTab: state => state.app.multiTab,
  lang: state => state.i18n.lang,
  mobile: state => state.user.mobile,
  headImgUrl: state => state.user.headImgUrl,
  company: state => state.user.company,
  companyId: state => state.user.companyId,
  type: state => state.user.type,
  id: state => state.user.id,
  faceImage1Url: state => state.user.faceImage1Url,
  wechatOpenId: state => state.user.wechatOpenId,
  uiCacheDbDictData: state => state.user.uiCacheDbDictData
}

export default getters
