import Vue from 'vue'
import {
  SET_NAV
} from '@/store/mutation-types'

const app = {
  state: {
    nav: '',
    sidebar: true,
    device: 'desktop',
    theme: '',
    layout: '',
    contentWidth: '',
    fixedHeader: false,
    fixSiderbar: false,
    autoHideHeader: false,
    color: null,
    weak: false,
    multiTab: true
  },
  mutations: {
    SET_NAV: (state, nav) => {
      Vue.ls.set(SET_NAV, nav)
      state.nav = nav
    }
  },
  actions: {
    navClick ({ commit }, nav) {
      console.log(nav)
      commit('SET_NAV', nav)
    }
  }
}

export default app
