import Vue from 'vue'
import store from '@/store/'
import {
  ACCESS_TOKEN,
  SET_NAV
} from '@/store/mutation-types'

export default function Initializer () {
  console.log(`API_URL: ${process.env.VUE_APP_API_BASE_URL}`)
  store.commit('SET_TOKEN', Vue.ls.get(ACCESS_TOKEN))
  store.commit('SET_NAV', Vue.ls.get(SET_NAV))
  // last step
}
