import { getInfo, sysLogout, loginFormSys } from '@/api/login'
import { queryAllDictItems } from '@/api/public'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { welcome } from '@/utils/util'
import { Toast } from 'mint-ui'

const user = {
  state: {
    token: '',
    username: '',
    welcome: '',
    avatar: '',
    roles: [],
    info: {},
    mobile: '',
    headImgUrl: '',
    company: '',
    type: '',
    id: '',
    faceImage1Url: '',
    companyId: '',
    permissions: [],
    wechatOpenId: '',
    uiCacheDbDictData: null // 缓存字典数据
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, { username, welcome }) => {
      state.username = username
      state.welcome = welcome
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_MOBILE: (state, mobile) => {
      state.mobile = mobile
    },
    SET_HEADIMGURL: (state, headImgUrl) => {
      state.headImgUrl = headImgUrl
    },
    SET_COMPANY: (state, company) => {
      state.company = company
    },
    SET_TYPE: (state, type) => {
      state.type = type
    },
    SET_ID: (state, id) => {
      state.id = id
    },
    SET_FACEIMAGE: (state, faceImage1Url) => {
      state.faceImage1Url = faceImage1Url
    },
    SET_COMPANYID: (state, companyId) => {
      state.companyId = companyId
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_INFO: (state, info) => {
      state.info = info
    },
    SET_PERM: (state, perm) => {
      state.permissions = perm
    },
    SET_WECHAT_OPEN_ID: (state, openId) => {
      state.wechatOpenId = openId
    },
    SET_UI_CACHE_DB_DICT_DATA: (state, uiCacheDbDictData) => {
      state.uiCacheDbDictData = uiCacheDbDictData
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        loginFormSys(userInfo)
          .then((response) => {
            if (!response.success) {
              reject(new Error(response.message))
              Toast(response.message)
              return
            }
            const result = response.result
            const userInfo = result.userInfo
            if (result.token == null) {
              reject(new Error('登录失败,未获取到token'))
            }
            // 判断账号类型，如果不是可登录账号则提示
            const userType = ['property', 'org']
            if (!userType.includes(userInfo.type)) {
              Toast('账户类型不正确，请联系管理员')
              return
            }
            // Vue.ls.set(ACCESS_TOKEN, result.access_token, 7 * 24 * 60 * 60 * 1000)
            // 鉴于wx-webview的局限性使用cookie存储
            var exp = new Date()
            exp.setTime(exp.getTime() + 365 * 24 * 60 * 60 * 1000)
            document.cookie = ACCESS_TOKEN + '=' + escape(result.token) + ';expires=' + exp.toGMTString()
            commit('SET_UI_CACHE_DB_DICT_DATA', result.sysAllDictItems)
            commit('SET_TOKEN', result.token)
            commit('SET_INFO', userInfo)
            commit('SET_NAME', { username: userInfo.username, realname: userInfo.realname, welcome: welcome() })
            commit('SET_AVATAR', userInfo.avatar)
            commit('SET_TYPE', userInfo.type)
            commit('SET_MOBILE', userInfo.phone)
            commit('SET_COMPANY', userInfo.company)
            commit('SET_COMPANYID', userInfo.orgId)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 获取用户信息
    GetInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo()
          .then((response) => {
            const result = response.result
            if (result == null) {
              reject(response)
              return
            }
            commit('SET_INFO', result)
            commit('SET_NAME', { username: result.username, realname: result.realname, welcome: welcome() })
            // commit('SET_AUDIT', result.auditResult)
            commit('SET_TYPE', result.type)
            commit('SET_MOBILE', result.mobile)
            commit('SET_COMPANY', result.company)
            commit('SET_COMPANYID', result.companyId)
            commit('SET_AVATAR', result.headImgUrl)

            commit('SET_HEADIMGURL', result.headImgUrl)
            commit('SET_ID', result.id)
            commit('SET_FACEIMAGE', result.faceImage1Url)

            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 登出
    Logout({ commit, state }) {
      return new Promise((resolve) => {
        sysLogout(state.token)
          .then(() => {
            resolve()
          })
          .catch(() => {
            resolve()
          })
          .finally(() => {
            commit('SET_TOKEN', '')
            commit('SET_NAME', '')
            commit('SET_AVATAR', '')
            commit('SET_ROLES', [])
            commit('SET_INFO', {})
            commit('SET_PERM', [])
            document.cookie = ACCESS_TOKEN + '=' + ''
            // Vue.ls.remove(ACCESS_TOKEN)
          })
      })
    },

    // 同步字典
    SyncAllDictItems({ commit }) {
      return new Promise((resolve) => {
        queryAllDictItems().then((res) => {
          commit('SET_UI_CACHE_DB_DICT_DATA', res.result)
          resolve(res.result)
        })
      })
    }
  }
}

export default user
